import { graphql, PageProps } from 'gatsby';
import React, { useContext } from 'react';
import ColouredPageSection from '../components/ColouredPageSection';
import Section from '../components/formatting/Section';
import WithHeroLayout from '../layouts/WithHeroLayout';
import loadable from '@loadable/component';
import FeaturedProgramme from '../components/FeaturedProgramme';
import generateSlug from '../util/generateSlug';
import { useTheme } from 'styled-components';
import SectionDescription from '../components/formatting/SectionDescription';
import FeaturedProgrammesContainer from '../components/FeaturedProgrammesContainer';
import SiteConfigContext from '../context/SiteConfigContext';
import MDX from '../cms/MDX';

const EventCategoryCalendar = loadable(() =>
  import('../components/EventCategoryCalendar')
);

interface Props extends PageProps {
  data: any;
}

const EventCategoryTemplate: React.FC<Props> = function (props) {
  const { data, location } = props;

  const { frontmatter } = data.event_category.childMdx;
  const {
    label,
    content,
    hero_image,
    color,
    excerpt,
    featured_programmes,
    display_calendar,
  } = frontmatter;
  const { edges: events } = data.events;
  const { edges: featuredProgrammes } = data.featured_programmes;

  const siteCfg = useContext(SiteConfigContext);

  const { block_description = '' } =
    siteCfg?.events_past_projects_configuration ?? {};

  const theme = useTheme();

  return (
    <WithHeroLayout
      pageTitle={label}
      bgImgSrc={hero_image}
      color={color}
      path={location?.pathname}
    >
      <Section>
        {excerpt && <SectionDescription>{excerpt}</SectionDescription>}
        <MDX>{content}</MDX>
      </Section>
      <ColouredPageSection
        firstColor={color}
        title="Featured Programmes"
        sectionDescription={`Find out about ${label}'s programmes and join their upcoming sessions.`}
      >
        <FeaturedProgrammesContainer>
          {featuredProgrammes
            .map(edge => edge.node.childMdx.frontmatter)
            .map(prog => (
              <FeaturedProgramme
                key={prog.label}
                color={color}
                labelTop={prog.type}
                title={prog.label}
                excerptRaw={prog.excerpt}
                featured_image={prog.hero_image}
                link={`/event_category/${generateSlug(prog.label)}`}
              />
            ))}
          <FeaturedProgramme
            color={theme.colors.purple}
            labelTop="Archive"
            title="Past Projects"
            excerptRaw={block_description.replace('$category', label)}
            featured_image={hero_image}
            link={`/event_category/${generateSlug(label)}/archive`}
          />
        </FeaturedProgrammesContainer>
      </ColouredPageSection>
      {display_calendar && (
        <Section>
          <EventCategoryCalendar events={events} color={color} />
        </Section>
      )}
    </WithHeroLayout>
  );
};

export const query = graphql`
  query(
    $absolutePath: String!
    $name: String!
    $featured_programmes: [String]
  ) {
    event_category: file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          label
          content
          hero_image
          color
          featured_programmes
          display_calendar
          excerpt
          type
        }
      }
    }

    events: allFile(
      filter: {
        sourceInstanceName: { eq: "event" }
        childMdx: { frontmatter: { categories: { in: [$name] } } }
      }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              date_ranges {
                start_date
                end_date
              }
              content
            }
          }
        }
      }
    }

    featured_programmes: allFile(
      filter: {
        sourceInstanceName: { eq: "event_category" }
        name: { in: $featured_programmes }
      }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              label
              excerpt
              hero_image
              type
            }
          }
        }
      }
    }
  }
`;

export default EventCategoryTemplate;
